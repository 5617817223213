import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Chip } from '../../../components/Chips';
import { MeetingListForDisplayHeaderDate } from './types';

export const MeetingListHeaderDate: React.FC<
  MeetingListForDisplayHeaderDate
> = ({ date, renewDate, isCycleBreak }) => {
  return (
    <div className="flex flex-col gap-2 border-neutral-muted border-t bg-neutral-xmuted px-8 py-2 text-left font-semibold text-sm sm:flex-row">
      {date}
      {isCycleBreak ? (
        <div className="w-auto self-center">
          <Chip color="yellow">
            <FormattedMessage
              defaultMessage="Free meetings will be renewed on {renewDate}"
              id="uYwbZp"
              values={{
                renewDate,
              }}
            />
          </Chip>
        </div>
      ) : null}
    </div>
  );
};
