import React, { ReactElement, ReactNode, useEffect, useState } from 'react';
import {
  Check,
  ChevronDown,
  Folder,
  LifeBuoy,
  Plus,
  Star,
  Wand2,
} from 'lucide-react';
import { Menu } from '../../../../components/Menu';
import { FormattedMessage } from 'react-intl';
import { Button } from '../../../../components/buttons';
import { EmojiField } from '../../../Common/icons/EmojiField';
import Magic from '../../../Common/icons/Magic';
import { AvailablePrompt } from './AIGenerated';
import { kMeetingKits } from '../../../../helpers/routes';
import { trackTranscriptAIToolsViewedMore } from '../../../../helpers/analytics';
import {
  useExploreKits,
  useSystemKit,
  useUsedKits,
} from '../../../../services/MeetingKits';
import { MeetingKit } from '../../../../graphql/operations';
import { cx } from '../../../../helpers/utils';
import { useToggleFavouriteMeetingKitItem } from '../../../../services/User';

/**
 * A nested dropdown with all the remaining AI Tools and Meeting Kits
 */
export function MoreAiToolsMenu(props: {
  loading: boolean;
  usedPrompts: Set<string>;
  trigger?: ReactElement;
  onClick: (id: AvailablePrompt) => void;
  showFavouriteButton?: boolean;
}): React.ReactElement<typeof Menu> {
  const {
    onClick,
    usedPrompts,
    loading,
    trigger,
    showFavouriteButton = true,
  } = props;

  const usedKits = useUsedKits();
  const systemKit = useSystemKit();
  const exploreKits = useExploreKits();

  const icon = (item: { icon: string }, fallback: ReactNode) =>
    item.icon ? (
      <EmojiField
        value={item.icon}
        defaultIcon={<Wand2 className="h-4 w-4 text-slate-600" />}
        EmojiProps={{
          unified: item.icon,
          size: 20,
        }}
      />
    ) : (
      fallback
    );

  type KitType = 'system' | 'used' | 'explore';
  const kits = new Map<string, MeetingKit & { type: KitType }>();

  kits.set(systemKit.id, { type: 'system', ...systemKit });
  for (const kit of usedKits)
    kits.set(kit.owner.sourceId ?? kit.id, { ...kit, type: 'used' }); // prioritise sourceId to make sure we don't double up with explore kits
  for (const kit of exploreKits) kits.set(kit.id, { ...kit, type: 'explore' });

  return (
    <Menu
      placement="bottom"
      open={true}
      onOpenChange={(isOpen) => {
        if (isOpen) trackTranscriptAIToolsViewedMore();
      }}
    >
      <Menu.Trigger>
        {trigger || (
          <Button
            className="w-full justify-center font-medium"
            loading={loading}
            endIcon={<ChevronDown size="1rem" />}
            variant="neutral-tertiary"
          >
            <FormattedMessage defaultMessage="View all AI prompts" />
          </Button>
        )}
      </Menu.Trigger>
      <Menu.Label>Meeting Kits</Menu.Label>
      {[...kits.values()].map((kit, index) => (
        <Menu
          open={index === 0}
          key={`${kit.type}${kit.id}`}
          icon={icon(kit, <Folder size="1.25rem" />)}
          label={kit.name}
        >
          {kit.items.length === 0 ? (
            <Menu.Item disabled key="empty">
              Empty
            </Menu.Item>
          ) : (
            kit.items.map((item, index) => {
              const used = usedPrompts.has(item.id);
              return (
                <div key={item.id ?? index} className="flex items-center">
                  <Menu.Item
                    truncate
                    onClick={() => {
                      onClick({
                        ...item,
                        type: kit.type,
                        title: item.name,
                        meetingKitId: kit.id,
                        meetingKitItemId: item.id,
                      });
                    }}
                    className={cx(
                      used ? 'bg-green-100' : undefined,
                      'w-full hover:bg-slate-50'
                    )}
                    disabled={used}
                    icon={
                      used ? <Check size="1.25rem" /> : icon(item, <Magic />)
                    }
                    key={item.id ?? index}
                  >
                    {item.name}
                  </Menu.Item>
                  {showFavouriteButton && (
                    <FavouriteButton
                      itemId={item.id}
                      title={item.name}
                      prompt={item.prompt}
                    />
                  )}
                </div>
              );
            })
          )}
        </Menu>
      ))}
      <Menu.Divider />
      <Menu.Item as="link" icon={<Plus size="1.25rem" />} to={kMeetingKits}>
        <FormattedMessage defaultMessage="Add new kit" id="P6Blha" />
      </Menu.Item>
      <Menu.Item
        as="a"
        icon={<LifeBuoy size="1.25rem" />}
        target="_blank"
        href="https://help.tactiq.io/en/articles/7907775-large-language-models-gpt-openai-privacy-and-tactiq"
      >
        <FormattedMessage defaultMessage="Help on using AI" id="gQP2qW" />
      </Menu.Item>
    </Menu>
  );
}

function FavouriteButton({
  itemId,
  title,
  prompt,
}: { itemId: string; title: string; prompt: string }) {
  const { isFavourite, toggle, loading } = useToggleFavouriteMeetingKitItem({
    id: itemId,
    title,
    prompt,
  });

  const [active, setActive] = useState(isFavourite); // Make a state island to avoid thrashing.
  const [queuedChange, setQueuedChange] = useState(false); // Only syncronise data in response to a click.

  // Instead of sending requests for every toggle, wait till the local state
  // is different to store state, and we are not already loading.
  useEffect(() => {
    if (active !== isFavourite && !loading && queuedChange) {
      toggle();
      setQueuedChange(false);
    }
  }, [toggle, active, isFavourite, loading, queuedChange]);

  return (
    <Menu.Item
      className={cx('hover:bg-slate-50')}
      fireTreeEvents={false}
      onClick={() => {
        setQueuedChange(true);
        setActive((current) => !current);
      }}
    >
      <Star
        className={cx(
          active
            ? 'fill-yellow-500 stroke-yellow-500'
            : 'fill-gray-200 stroke-gray-200'
        )}
        size="1rem"
      />
    </Menu.Item>
  );
}
