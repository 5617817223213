import { useSelector } from 'react-redux';
import {
  selectTeam,
  selectUid,
  selectUserCancellationDiscountCouponUsed,
} from '../redux/selectors';
import { useMutation } from '@apollo/client';
import {
  MeetingKitItem,
  Team,
  UpdateUserDisplayNameDocument,
  ToggleFavouriteMeetingKitItemDocument,
} from '../graphql/operations';
import { MutationResult } from './helpers';
import { RootState } from '../redux/store';
import { useExploreKits, useSystemKit, useUsedKits } from './MeetingKits';
import {
  trackQuickPromptStarred,
  trackQuickPromptUnstarred,
} from '../helpers/analytics';

/**
 * The id of the currently logged in user
 */
export function useCurrentUserId(): string {
  const userId = useSelector(selectUid);
  if (!userId) throw new Error('useCurrentUserId used while unauthenticated');
  return userId;
}

/**
 * The team of the current user
 */
export function useCurrentTeam(): Team | undefined {
  return useSelector(selectTeam);
}

/** Updating users displayName */
export function useUpdateUserDisplayName(): MutationResult<
  typeof UpdateUserDisplayNameDocument
> {
  const [fn, { data, loading, error }] = useMutation(
    UpdateUserDisplayNameDocument
  );

  return {
    request: (variables) => fn({ variables: { input: variables.input } }),
    data,
    error,
    loading,
  };
}

export function getCurrentUserCancellationDiscountCouponUsed(): string {
  return useSelector(selectUserCancellationDiscountCouponUsed) ?? '';
}

/**
 * Join the users favourite meeting kit item ids with the meeting kits
 */
export function useFavouriteMeetingKitItems(): MeetingKitItem[] {
  const favourites = useSelector(
    (state: RootState) => state.user.favouriteMeetingKitItems
  );
  const usedKits = useUsedKits();
  const systemKit = useSystemKit();
  const exploreKits = useExploreKits();

  // Make a map of all meeting kit items
  const items = new Map(
    [
      ...usedKits.flatMap((kit) => kit.items),
      ...exploreKits.flatMap((kit) => kit.items),
      ...systemKit.items,
    ].map((item) => [item.id, item])
  );

  // If the user has less than 6 favourites pad them out with system prompts
  const paddedPromptIds = [...(favourites ?? [])];
  if (paddedPromptIds.length < 6) {
    paddedPromptIds.push(
      ...systemKit.items
        .map((item) => item.id)
        .filter((systemId) => !paddedPromptIds.includes(systemId))
        .slice(0, Math.max(0, 6 - paddedPromptIds.length))
    );
  }

  // Convert the ids into meeting kits items
  return paddedPromptIds?.flatMap((id) => {
    const item = items.get(id);
    return item ? [item] : [];
  });
}

/**
 * Toggle the favourite status of a meeting kit item.
 */
export function useToggleFavouriteMeetingKitItem(props: {
  title: string;
  prompt: string;
  id: string;
}) {
  const { title, prompt, id } = props;
  const favourites = useSelector(
    (state: RootState) => state.user.favouriteMeetingKitItems ?? []
  );

  const [fn, { error, loading }] = useMutation(
    ToggleFavouriteMeetingKitItemDocument
  );

  if (error) throw error;
  const isFavourite = favourites.includes(id);

  return {
    loading,
    isFavourite,
    toggle: () => {
      isFavourite
        ? trackQuickPromptUnstarred({ title, prompt })
        : trackQuickPromptStarred({ title, prompt });

      fn({
        variables: { input: id },
      });
    },
  };
}
